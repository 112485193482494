<template>
  <div>
    <div v-show="this.$route.name === 'booking-details'">
      <Header
        class="flex"
        :back="true"
        :text="$t('Views.Calendar.calendar')"
        :route="backRoute"
        :title="booking.Subject"
      >
        <h5 class="header-title">
          {{ booking.Subject }}
        </h5>
      </Header>
      <div class="scrollable">
        <Card position="tab">
          <BTabs
            justified
            lazy
          >
            <b-tab
              :title="$t('Components.BookingDetails.booking')"
              :title-item-class="isOrganizer ? 'nav-title' : 'nav-title-attendee'"
              :active="isActive('booking')"
              @click="setLastTab('booking')"
            >
              <BookingDetails :booking="booking" />
            </b-tab>
            <b-tab
              v-if="isOrganizer"
              :title="$t('Components.BookingDetails.edit')"
              title-item-class="nav-title"
              :active="isActive('edit')"
              @click="setLastTab('edit')"
            >
              <BookingEdit :booking="booking" />
            </b-tab>
          </BTabs>
        </Card>
      </div>
    </div>
    <div>
      <keep-alive>
        <router-view />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import Card from '@/components/card/card.vue';
import Header from '@/components/headers/header.vue';
import BookingDetails from '@/components/details/booking.details.vue';
import { BTabs, BTab } from 'bootstrap-vue';
import UserState from '@/singletons/user.state.singleton';
import BookingEdit from './calendar.edit.booking.vue';

export default {
  components: {
    Card,
    Header,
    BTabs,
    BTab,
    BookingDetails,
    BookingEdit,
  },
  computed: {
    booking() {
      return UserState.bookings.find((booking) => booking.Bid === parseInt(this.$route.params.bid, 10));
    },
    backRoute() { return `/calendar?date=${this.$route.query.date}`; },
    isOrganizer() {
      if (this.booking) {
        return (this.booking.Owner === UserState.username);
      }
      return '';
    },
  },
  methods: {
    setLastTab(arg) {
      localStorage.setItem('last-tab', arg);
    },
    isActive(arg) {
      const latest = localStorage.getItem('last-tab');
      return latest === arg;
    },
  },
};
</script>

<style lang="scss">
  .nav-tabs {
    border: 0;
    border-bottom: solid 2px #D9D9D9;
    border-bottom: solid 2px var(--border-color);
    margin-bottom: 0;
  }
  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    padding-bottom: 1.5rem ;
    border-color: var(--primary-color);
    color: black;
  }
  .nav-tabs .nav-link {
    border: 0;
    color: black;
  }
  .nav-link.active {
    border: 0;
    border-bottom: solid 4px var(--primary-color);
  }
  .nav-title {
    font-size: 1.125rem;
    font-weight: 600;
  }
  .nav-title-attendee {
    font-size: 1rem;
    font-weight: 600;
    .nav-link.active {
      cursor: default;
      border-bottom: solid 2px var(--modal-bg-color);
    }
  }
</style>
