<template>
  <div>
    <Card
      position="no-padding-sides"
      margin-sides="no-margin-sides"
    >
      <p class="margin margin margin-bottom normal-semi">
        {{ $t('Components.EditBookingTab.title') }}
      </p>
      <BFormInput
        v-model="bookingTemplate.Subject"
        :placeholder="$t('Views.Book.title')"
      />
    </Card>
    <Card
      position="no-padding-sides"
      margin-sides="no-margin-sides"
    >
      <div class="edit_booking-card">
        <p class="padding padding-small padding-top normal-semi">
          {{ $t('Components.EditBookingTab.resource') }}
        </p>
        <div
          v-if="bookingTemplate.Source"
          class="edit_booking-card standard resource"
        >
          <svg-icon
            class="resource-icon_edit"
            :src="iconSrc"
          />
          <div class="overflow-hidden">
            <p class="normal-semi elipsis">
              {{ resourceName }}
            </p>
            <p class="small-regular elipsis">
              {{ company }} {{ floorname }}
            </p>
          </div>
          <div @click="bookingTemplate.Source = null">
            <svg-icon
              class="resource-icon_edit cross"
              src="/icons/delete_cross.svg"
            />
          </div>
        </div>
      </div>
    </Card>
    <Card
      position="no-padding-sides"
      margin-sides="no-margin-sides"
    >
      <div class="edit_booking-card ">
        <p class="padding padding-top normal-semi">
          {{ $t('Components.EditBookingTab.datetime') }}
        </p>
        <div
          class="edit_booking-card standard padding padding-small padding-all"
        >
          <Button
            size="tiny"
            variant="pastel-primary"
            @click="changeDate"
          >
            <div class="edit_booking-card standard edit__datettime--btn">
              <svg-icon
                class="resource-icon_edit"
                src="/icons/calendar_date_icon.svg"
              />
              <p class="normal-semi">
                {{ date.getMonthDateNrString() }}
              </p>
            </div>
            <div class="edit_booking-card standard">
              <svg-icon
                class="resource-icon_edit"
                src="/icons/new_clock.svg"
              />
              <p class="normal-semi">
                {{ bookingTime }}
              </p>
            </div>
          </Button>
        </div>
      </div>
    </Card>
    <Card
      position="no-padding-sides"
      margin-sides="no-margin-sides"
    >
      <div class="edit_booking-card margin margin margin-top margin-bottom">
        <p class="padding padding-top normal-semi">
          {{ $t('Components.EditBookingTab.invited') }}
        </p>
        <div
          class="edit_booking-card standard padding padding-small padding-all"
        >
          <Button
            size="tiny"
            variant="pastel-primary"
            class="edit_booking-card standard"
            @click="changeInvited"
          >
            <svg-icon
              class="resource-icon_edit"
              src="/icons/invited_users_icon.svg"
            />
            <p class="normal-semi">
              {{ nrOfInvited }}
            </p>
          </Button>
        </div>
      </div>
    </Card>
    <Card
      position="no-padding-sides"
      margin-sides="no-margin-sides"
    >
      <div class="edit_booking-card margin margin margin-top margin-bottom">
        <p class="padding padding-small padding-top normal-semi">
          {{ $t('Components.EditBookingTab.private') }}
        </p>
        <BFormCheckbox
          v-model="bookingTemplate.Private"
          switch
        />
      </div>
    </Card>
    <Card
      position="bottom no-padding-sides"
      margin-sides="no-margin-sides"
    >
      <div class="flex flex-gap">
        <Button
          variant="danger"
          style="margin:auto;"
          @click="cancel"
        >
          {{ $t('Components.BookingTab.cancel_booking') }}
        </Button>
        <Button
          :disabled="updateBtnDisabled"
          variant="primary"
          style="margin:auto;"
          @click="update"
        >
          {{ $t('Components.EditBookingTab.update') }}
        </Button>
      </div>
    </Card>
  </div>
</template>

<script>
import Card from '@/components/card/card.vue';
import Button from '@/components/button/button.vue';
import CompanyState from '@/singletons/company.state.singleton';
import ExtendedDate from '@/classes/extended.date.class';
import { BFormInput, BFormCheckbox } from 'bootstrap-vue';
import { updateBooking, deleteBooking } from '@/util/api';
import BookingService from '@/Services/Bookings/bookings.service';
import CompanyService from '@/Services/Company/company.service';
import bookingTemplate from '@/views/book/template.booking';
import ErrorMessage from '@/Services/Error/Error';
import CustomEvent from '@/classes/custom.event.class';
import WeekState from '@/singletons/week.state.singleton';

export default {
  components: {
    Card,
    Button,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    BFormCheckbox,
  },
  props: {
    booking: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selectedSlot: null,
      text: '',
      date: new ExtendedDate(bookingTemplate.From || this?.booking?.From),
      bookingTemplate,
      bookingTemplateStartState: null,
      keepTemplate: false,
      updateBtnDisabled: true,
      viewInstance: 0,
    };
  },
  computed: {
    iconSrc() {
      return CompanyState.zoneTypes.find((type) => type.Constant === this?.booking?.ZoneType)?.Icon;
    },
    resourceName() {
      return CompanyState.zones.find((zone) => zone.Zid === this?.booking?.Zid)?.Name;
    },
    bookingTime() {
      return `${new ExtendedDate(bookingTemplate.From).localeTimeString()} - ${new ExtendedDate(bookingTemplate.Until).localeTimeString()}`;
    },
    nrOfInvited() {
      return bookingTemplate.Attendees.length;
    },
    zone() {
      return CompanyService.getZoneByZid(this.booking?.Zid);
    },
    floor() {
      return CompanyService.getFloorByParent(this.zone?.ParentZone);
    },
    floorname() {
      return this.floor?.Name;
    },
    company() {
      return CompanyService.getCompanyByCid(this.floor?.Parent)?.Name;
    },
  },
  watch: {
    bookingTemplate: {
      handler() {
        if (this.bookingTemplateStartState && bookingTemplate.templateFor === 'edit') {
          // Disable update button if bookingTemplate has been changed from the originally loaded one

          this.updateBtnDisabled = this.deepEqual(this.bookingTemplateStartState, bookingTemplate);
        }
      },
      deep: true,
    },
  },
  created() {
    this.setUpBookingTemplate().then(() => {
      // Await template initialization & clone it as the 'start state'
      this.bookingTemplateStartState = JSON.parse(JSON.stringify(bookingTemplate));
    });
  },
  destroyed() {
    if (!this.keepTemplate) {
      this.resetBookingData();
    }
  },
  methods: {
    async setUpBookingTemplate() {
      if (bookingTemplate.templateFor !== 'edit') this.resetBookingData();
      bookingTemplate.templateFor = 'edit';
      bookingTemplate.Attendees = bookingTemplate.Attendees || this.booking.Attendees || [];
      bookingTemplate.From = bookingTemplate.From || this.booking.From;
      bookingTemplate.Until = bookingTemplate.Until || this.booking.Until;
      bookingTemplate.Subject = bookingTemplate.Subject || this.booking.Subject;
      bookingTemplate.Private = bookingTemplate.Private || this.booking.Private;
      bookingTemplate.Source = bookingTemplate.Source || this.booking.Source;
    },
    async cancel() {
      try {
        await deleteBooking(this.booking.Bid);
        this.keepTemplate = false;
        const evt = new CustomEvent(this.$t('Components.EditBookingTab.remove_booking'), 'global_error_message', 'success');
        evt.dispatch();
        this.$router.push('/agenda');
      } catch (e) {
        let evtMsg;
        this.keepTemplate = false;
        if (e.response && e.response.data) {
          evtMsg = ErrorMessage.getBookingError(e.response.data);
        } else evtMsg = ErrorMessage.getBookingError(e);
        const evt = new CustomEvent(evtMsg);
        evt.dispatch();
      }
    },
    async checkOut() {
      await BookingService.checkOutBooking(this.booking.Bid);
    },
    async update() {
      if (!bookingTemplate.Source) {
        try {
          if (!this.booking.ExternalId) {
            await deleteBooking(this.booking.Bid).then(() => {
              this.keepTemplate = false;
              const evt = new CustomEvent(this.$t('Components.EditBookingTab.remove_booking'), 'global_error_message', 'success');
              evt.dispatch();
              this.$router.push('/agenda');
            });
          }
          await this.checkOut(this.booking.Bid);
        } catch (e) {
          const evtMsg = (e.response && e.response.data) ? ErrorMessage.getBookingError(e.response.data) : ErrorMessage.getBookingError(e);
          const evt = new CustomEvent(evtMsg);
          evt.dispatch();
        }
      }

      // Merge booking info into new object
      const update = {
        ...this.booking,
        ...{
          Subject: bookingTemplate.Subject,
          Private: bookingTemplate.Private,
          LastModified: ExtendedDate.now(),
          From: bookingTemplate.From,
          Until: bookingTemplate.Until,
          Attendees: bookingTemplate.Attendees,
        },
      };
      console.log(update);
      await updateBooking(update, update.Bid).then(() => {
        // When update succeeded, notify about update
        this.keepTemplate = false;
        const evt = new CustomEvent(this.$t('Components.EditBookingTab.updated_booking'), 'global_error_message', 'success');
        evt.dispatch();
        Object.assign(this.booking, update);
        this.$router.push('/agenda');
      }).catch((e) => {
        const evtMsg = (e.response && e.response.data) ? ErrorMessage.getBookingError(e.response.data) : ErrorMessage.getBookingError(e);
        const evt = new CustomEvent(evtMsg);
        evt.dispatch();
      });
    },
    changeDate() {
      this.keepTemplate = true;
      this.$router.push({
        name: 'calendar-booking-edit-time',
        query: {
          date: this.$route.query.date || WeekState.date?.getDateString(),
        },
      });
    },
    changeInvited() {
      this.keepTemplate = true;
      this.$router.push(`/calendar/booking/${this.booking.Bid}/attendees`);
    },
    resetBookingData() {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key] of Object.entries(bookingTemplate)) {
        bookingTemplate[key] = null;
      }
    },
    deepEqual(obj1, obj2) {
    // If both values are identical (including both being null), return true
      if (obj1 === obj2) {
        return true;
      }

      // If either is not an object, return false (they're not equal)
      if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
        return false;
      }

      // Get the keys of both objects
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      // If the number of keys is different, the objects are not equal
      if (keys1.length !== keys2.length) {
        return false;
      }

      // Recursively check equality of each key-value pair
      // eslint-disable-next-line no-restricted-syntax
      for (const key of keys1) {
        if (!keys2.includes(key) || !this.deepEqual(obj1[key], obj2[key])) {
          return false;
        }
      }

      // If all keys and values match deeply, the objects are equal
      return true;
    },
  },
};
</script>

<style lang="scss">
  .flex.flex-gap {
    gap: 1rem;
  }
  .resource-icon_edit {
    svg {
      width: 1rem;
      height: 1rem;
    }
    &.cross {
      svg {
        cursor: pointer;
      }
    }
  }
  .edit_booking-card {
    display: flex;
    justify-content: space-between;
    &.standard {
      gap: .8rem;
      fill: var(--primary-color);
      p {
        color: var(--primary-color);
      }
    }
    &.edit__datettime--btn {
      justify-content: flex-start;
    }

    &.resource {
      max-width: 50%;
      svg {
        width: 1.2rem;
        height: 1.2rem;
        margin-top: .8rem;
      }
    }
  }
</style>
