<template>
  <div
    class="calendar-list__wrapper"
  >
    <div class="margin margin-right small-padd">
      <RoundResourceIcon
        :src="iconSrc"
        type="resource"
      />
    </div>
    <div
      class="info overflow-hidden margin margin-right margin-medium"
      @click="showBookingDetails"
    >
      <p
        v-if="booking.Subject"
        class="normal-semi elipsis"
      >
        {{ booking.Subject }}
      </p>
      <div class="booking-time-location overflow-hidden">
        <svg-icon
          v-if="booking.ExternalId"
          src="/icons/office-sso.svg"
        />
        <p class="small-regular elipsis">
          {{ bookingFrom }} - {{ bookingUntil }}, {{ bookingLocationName }}
        </p>
      </div>
    </div>
    <div class="end-icons padding padding-small padding-top">
      <template>
        <svg-icon
          v-if="needCheckIn"
          class="issue-icon"
          src="/icons/issue_symbol.svg"
        />
      </template>
      <svg-icon
        v-if="booking.Private"
        src="/icons/calendar_lock.svg"
      />
      <div
        v-if="booking.Zid"
        @click="showOnMap()"
      >
        <svg-icon
          class="end-icon"
          src="/icons/map_navigation_pin.svg"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ExtendedDate from '@/classes/extended.date.class';
import CompanyState from '@/singletons/company.state.singleton';
import RoundResourceIcon from '@/components/icons/round.resource.icon.vue';
import PhoneService from '@/Services/Phone/phone.service';
import LocationController from '@/views/location.selection/location.selection.controller';
import UserState from '@/singletons/user.state.singleton';
import WeekState from '@/singletons/week.state.singleton';
import QueryMixin from '../../../mixins/query.mixin';
import mapController from '../../../classes/map/map.controller';

export default {
  components: {
    RoundResourceIcon,
  },
  mixins: [QueryMixin],
  props: {
    booking: {
      type: Object,
      default: null,
    },
  },
  computed: {
    bookingFrom() {
      return new ExtendedDate(this?.booking?.From).localeTimeString();
    },
    bookingUntil() {
      return new ExtendedDate(this?.booking?.Until).localeTimeString();
    },
    bookingLocationName() {
      return CompanyState?.zones.find((zone) => zone?.Zid === this?.booking?.Zid)?.Name;
    },
    iconSrc() {
      return CompanyState.zoneTypes.find((type) => type.Constant === this?.booking?.ZoneType)?.Icon;
    },
    needCheckIn() {
      const MIN_15 = 1000 * 60 * 15;
      const now = new ExtendedDate().getTime();
      return ((this?.booking?.From - now) < MIN_15) && this?.booking?.CheckIn === null && this?.booking?.NoShow !== null;
    },
  },
  methods: {
    async showOnMap() {
      const currentZone = CompanyState?.zones.find((zone) => zone?.Zid === this?.booking?.Zid);
      const floor = CompanyState.zones.find((parent) => parent.Zid === currentZone.ParentZone);
      if (floor.Zid !== UserState.selectedLocation.Zid) {
        await LocationController.setLocationByFloorZid(floor.Zid);
      }
      if (PhoneService.isInPhoneApp()) {
        this.$router.push({ name: 'map', query: { zid: this?.booking?.Zid } });
      } else {
        this.replaceQuery({ zid: this?.booking?.Zid });
        mapController.showMapMarker(currentZone, true);
      }
    },
    showBookingDetails() {
      this.$router.push({
        path: `/calendar/booking/${this.booking.Bid}`,
        query: {
          date: this.$route.query.date || WeekState.date?.getDateString(),
        },
      });
    },
  },
};
</script>
<style lang="scss">
  .calendar-list__wrapper {
    display: flex;
    flex-grow: 1;
  }
  .small-padd {
    padding-top: 0.2rem;
  }
  .calendar-list__wrapper.last-item {
    display: flex;
    flex-grow: 1;
    margin-bottom: 0rem;
  }
  .info {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-size: 0.8rem;
    cursor: pointer;
    .booking-time-location{
      display: flex;
      flex-direction: row;
      svg{
        width: 1.2rem;
        height: 1.2rem;
        padding-bottom: 0.2rem;
        fill: var(--primary-color);
      }
    }
    p {
      margin-bottom: 0;
    }
  }
  .end-icons {
    gap:1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    svg {
        fill: var(--primary-color);
    }
    .issue-icon {
      svg {
        width: 1.3rem;
        height: 1.3rem;
        fill: #c90c50;
      }
    }
    .end-icon {
      cursor: pointer;
      svg {
        margin-right: 0;
      }
    }
  }
</style>
