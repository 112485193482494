<template>
  <div>
    <Header
      :back="true"
      :route="'/admin'"
      :text="$t('Components.Sidebar.Admin')"
      :title="$t('Components.Admin.esync')"
    >
      <h5 class="center-text header-title">
        {{ $t('Components.Admin.esync') }}
      </h5>
    </Header>
    <div class="scrollable">
      <Card
        position="tab"
      >
        <BTabs
          justified
          lazy
        >
          <b-tab
            title-item-class="nav-title"
            :title="$t('Components.Admin.sync')"
          >
            <AdminEsyncSyncView />
          </b-tab>
          <b-tab
            title-item-class="nav-title"
            :title="$t('Components.Admin.edit')"
          >
            <AdminEsyncEditView />
          </b-tab>
        </BTabs>
      </Card>
    </div>
  </div>
</template>

<script>

import Header from '@/components/headers/header.vue';
import Card from '@/components/card/card.vue';
// import CompanyState from '@/singletons/company.state.singleton';

// import { getSyncedCompany } from '@/util/api';
import { BTabs, BTab } from 'bootstrap-vue';

import EsyncController from './esync.controller';

import AdminEsyncEditView from './admin.esync.edit.view.vue';
import AdminEsyncSyncView from './admin.esync.sync.view.vue';

export default {
  components: {
    Header,
    Card,
    BTabs,
    BTab,
    AdminEsyncEditView,
    AdminEsyncSyncView,
  },
  data() {
    return {
      active: false,
      debug: false,
      tenantId: '',
      EsyncController,
    };
  },
  async mounted() {
    this.tenantId = EsyncController.tenantId;
    this.active = EsyncController.active;
    this.debug = EsyncController.debug;
  },
};

</script>

<style lang="scss">

 .list-item {
    display: inline-flex;
    margin: 0;
    padding: 0;
    * {
      margin-bottom: auto;
      margin-top: auto;
    }

    .custom-area {
      margin-left: auto;
    }
  }

  .nav-tabs {
    border: 0;
    border-bottom: solid 2px #D9D9D9;
    border-bottom: solid 2px var(--border-color);
    margin-bottom: 0;
  }
  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    padding-bottom: 1.5rem ;
    border-color: var(--primary-color);
    color: black;
  }
  .nav-tabs .nav-link {
    border: 0;
    color: black;
  }
  .nav-link.active {
    border: 0;
    border-bottom: solid 4px var(--primary-color);
  }
  .nav-title {
    font-size: 1.125rem;
    font-weight: 600;
  }

</style>
