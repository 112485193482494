<template>
  <div
    class="kebab_container"
    @click="opens()"
  >
    <div
      class="kebab_icon_container"
      :class="open ? 'show-background' : ''"
    >
      <SVGIcon
        class="kebab-icon"
        :src="'/icons/kebab_menu.svg'"
      />
    </div>
    <div
      v-if="open"
    >
      <ul class="kebab_menu-open">
        <li
          v-for="(option, index) in options"
          :key="index"
          @click="option.action"
        >
          <SVGIcon
            :class="viewSelect && isSelected(option) ? 'visible' : 'hidden'"
            class="icon"
            :src="'/icons/check-solid.svg'"
          />
          <p
            v-if="!viewSelect"
            class="normal-regular margin margin-small margin-all"
            :class="option.isDanger ? 'danger' : ''"
          >
            {{ option.text }}
          </p>
          <p
            v-else
            class="normal-regular margin margin-small margin-all"
            :class="isSelected(option) ? 'selected' : ''"
          >
            {{ option.text }}
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import SVGIcon from '@/components/globals/SVGIcon/SVGIcon.vue';
import PhoneKebabMenu from '@/Services/Phone/phone.kebab.menu';
import MapState from '@/singletons/map.state.singleton';

export default {
  components: {
    SVGIcon,
  },
  props: {
    // Requires [{text: String, isDanger: bool, action: Function}]
    options: {
      type: Array,
      required: true,
    },
    viewSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
      hackySolution: false,
    };
  },
  mounted() {
    window.addEventListener('click', () => { if (this.open && !this.hackySolution) this.open = false; });
  },
  methods: {
    opens() {
      this.hackySolution = true;
      setTimeout(() => { this.hackySolution = false; }, 300);

      // Phone version
      if (PhoneKebabMenu.showIfInPhoneApp(this.options) != null) {
        return;
      }

      // Web version
      this.open = !this.open;
    },
    isSelected(option) {
      return (MapState.cameraViewType === option.text || MapState.customCameraViewType === option.text);
    },
  },

};
</script>

<style lang="scss">
.kebab_container{
  position: relative;
  ul.kebab_menu-open{
    border-radius: 0.5rem;
    position: absolute;
    right: 0;
    z-index: 999;
    margin-top: -6px;
    margin-right: 0.2rem;
    padding: 0.5rem 0;
    background-color: var(--background-kebab-color);
    list-style: none;
    box-shadow: 7px 6px 6px -2px rgba(153,153,153,0.5);
    -webkit-box-shadow: 7px 6px 6px -2px rgba(153,153,153,0.5);
    -moz-box-shadow: 7px 6px 6px -2px rgba(153,153,153,0.5);
    li{
      margin: 0.1rem 1.4rem 0 0;
      list-style: none;
      white-space: nowrap;
      padding:0;
      display: flex;
      flex-direction: row;
      p{
        color: var(--secondary-font-color);
        &.danger{
          color: var(--danger-color);
        }
        &.selected {
          gap: 1rem;
          color: var(--primary-color);
        }
      }
      .icon{
         &.visible{
          visibility: visible
         }
          &.hidden{
          visibility: hidden
         }
        svg{
          width: 1rem;
          margin-left: 0.4rem;
          margin-right: 0.2rem;
          color: var(--primary-color);
          fill: var(--primary-color);
        }
      }
      &:hover{
        cursor: pointer;
      }
    }
  }
  .kebab_icon_container{
    display: flex;
    margin-right: -0.5rem;
    // position: absolute;
    height: 2.5rem;
    width: 2.5rem;
    &:hover{
      cursor: pointer;
    }
    &.show-background{
    background-color: var(--background-kebab-color);
    border-radius: 50%;
    }
    .kebab-icon{
      margin: auto;
      color: var(--secondary-font-color);
      fill: var(--secondary-font-color);
    }
  }
}
</style>
