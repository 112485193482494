<template>
  <div class="debug-container">
    <div v-if="logs.length">
      <div
        v-for="log, key of logs"
        :key="key"
      >
        <h6>
          {{ log.createdat }}
        </h6>
        <p>
          {{ log.name }}
        </p>
        <p class="error">
          {{ log.error }}
        </p>
        <pre>
          <code class="meta">
            {{ beautify(log.meta) }}
          </code>
        </pre>
      </div>
      <br>
    </div>
    <p v-else>
      No logs
    </p>
  </div>
</template>

<script>

export default {
  // [{name, error, meta, createdat}]
  props: {
    logs: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  methods: {
    beautify(string) {
      return JSON.parse(string);
    },
  },
};

</script>

<style lang="scss">

  .debug-container{
    background-color: rgb(22, 22, 22);
    overflow-y: auto;
    min-height: 7rem;
    max-height: 25rem;
    padding: 1rem;

    h6, p, code, pre {
      margin: 0;
      font-size: 0.8rem;
      color: white;
      &.error{
        color: var(--danger);
      }
      &.meta{
        color: var(--warning);
      }
    }
  }

</style>
