<template>
  <div
    class="check_box_container"
    :class="checkboxState"
  >
    <div class="checkbox_icon">
      <svg-icon
        v-if="checkboxState === 'all_selected'"
        src="/icons/check_in_checkbox.svg"
      />
      <svg-icon
        v-if="checkboxState === 'some_selected'"
        src="/icons/line_in_checkbox.svg"
      />
    </div>
  </div>
</template>

<script>
import CHECKBOX_ENUMS from './checkbox.enum';

const { ALL_SELECTED, NON_SELECTED, SOME_SELECTED } = CHECKBOX_ENUMS;
export default {
  props: {
    state: {
      type: Number,
      required: false,
      default: NON_SELECTED,
    },
  },

  computed: {
    checkboxState() {
      switch (this.state) {
        default:
        case NON_SELECTED:
          return 'none_selected';
        case SOME_SELECTED:
          return 'some_selected';
        case ALL_SELECTED:
          return 'all_selected';
      }
    },
  },

};
</script>

<style lang="scss">
 .check_box_container{
  border: 1px solid var(--border-color-darker);
  border-radius: 5px;
  width: 1.5rem;
  height: 1.5rem;
  &.all_selected, &.some_selected{
    border-color: var( --primary-color-light);
    background-color: var( --primary-color-light);
  }
  .checkbox_icon{
    svg{
      height: 1.5rem;
      width: 1.5rem;
      padding: 0.225rem;
      display: flex;
      margin: auto;;
      fill: var(--primary-color);
    }
  }
 }
</style>
