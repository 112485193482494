<template>
  <div>
    <div
      class="checkbox_group--container"
      @click="selectAll()"
    >
      <div class="content-container">
        <RoundResourceIcon
          v-if="header && header.icon"
          :src="header.icon"
          type="settings reverse"
        />
        <h6 class="checkbox_link--text normal-semi">
          {{ header.title }}
        </h6>
      </div>
      <div class="flex-center">
        <Checkbox :state="groupState" />
      </div>
    </div>
    <div
      v-for="option of options"
      :key="option.value"
      class="checkbox_group--container margin-left-indent"
      @click="select(option.value)"
    >
      <div class="content-container">
        <RoundResourceIcon
          v-if="option.icon"
          :src="option.icon"
          type="settings reverse"
        />
        <h6 class="checkbox_link--text normal-regular">
          {{ option.title }}
        </h6>
      </div>
      <div class="flex-center">
        <Checkbox :state="content.includes(option.value) ? ALL_SELECTED : NON_SELECTED" />
      </div>
    </div>
  </div>
</template>

<script>

import RoundResourceIcon from '@/components/icons/round.resource.icon.vue';
import Checkbox from './checkbox.vue';

import CHECKBOX_ENUMS from './checkbox.enum';

const { ALL_SELECTED, NON_SELECTED, SOME_SELECTED } = CHECKBOX_ENUMS;
export default {
  components: {
    RoundResourceIcon,
    Checkbox,
  },
  model: {
    prop: 'selections',
    event: 'change',
  },
  props: {
    // { title, icon, selectable} ->
    header: {
      type: Object,
      required: true,
    },
    // [{ title, value, icon, selectable } ]
    options: {
      type: Array,
      required: true,
    },
    // the values selected by default
    selections: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      content: this.selections,
      allOptions: this.options.map(({ value }) => value),
      ALL_SELECTED,
      NON_SELECTED,
    };
  },

  computed: {
    groupState() {
      if (!this.content.length) {
        return NON_SELECTED;
      }
      if (this.content.length === this.allOptions.length) {
        return ALL_SELECTED;
      }
      return SOME_SELECTED;
    },

  },

  methods: {
    select(id) {
      if (this.content.includes(id)) {
        const index = this.content.findIndex((x) => x === id);
        this.content.splice(index, 1);
      } else {
        this.content.push(id);
      }
      this.$emit('change', this.content);
    },

    selectAll() {
      if (this.content.length !== this.allOptions.length) {
        this.content = [...this.allOptions];
        this.$emit('change', this.content);
      } else {
        this.content = [];
        this.$emit('change', this.content);
      }
    },

  },

};
</script>

<style lang="scss">
.checkbox-link-exact-active
.checkbox_group--container {
  background: #def3e7;
  border-radius: 0.5rem;
}

.checkbox_group--container {
  margin: 0.3rem 0;
  padding: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  justify-content: space-between;

  &:hover{
    text-decoration: none !important;
    cursor: pointer;
  }
  .content-container{
    display: flex;
    gap:1rem;
  }
}

.checkbox_group--container.active {
  background: #def3e7;
  border-radius: 0.5rem;
}

.checkbox_group--container.margin-left-indent {
  margin-left:1rem;
}

.checkbox_link--text{
  margin-top: auto;
  margin-bottom: auto;
}

.checkbox_icon--right {
  margin: auto 0;
  font-size: 1.3rem;
}

.custom-area {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.flex-center{
  margin: auto 0;
  display: flex;
}

</style>
