/* eslint-disable no-unused-vars */

import PhoneService from './phone.service';

/**
 * Native kebab menu in the phone. Possible by communicating with
 * the app and telling it to create the native menu. When user picks
 * an alternative, the kebab menu gets notified.
 *
 * - Create a PhoneKebabMenu with options (each option has an action (func)).
 * - Call menu.Show().
 * - Action gets called when user selects an option.
 *
 * Menu can only be used once after calling **show()**. After a response
 * is received, the options are cleared.
 */
class PhoneKebabMenu {
  //
  // MARK: - Constructor & destroy

  /**
   * Creates a kebab menu with options specified. Options with isDanger set
   * to true will be displayed as destructive in red.
   * @param {[{text: String, isDanger: bool, action: Function}]} options
   */
  constructor(options) {
    this.options = options;
  }

  /**
   * Show a phone native kebab menu if the user is in
   * the phone app.
   * @param {[{text: String, isDanger: bool, action: Function}]} options
   * @returns Instance of PhoneKebabMenu if it was created and shown.
   * Returns **null** if user is not in phone app.
   */
  static showIfInPhoneApp(options) {
    if (!PhoneService.isInPhoneApp() || PhoneService.isDebuggingWebAsPhone()) {
      return null;
    }
    const menu = new PhoneKebabMenu(options);
    menu.show();
    return menu;
  }

  // MARK: - Show & listen for response

  show() {
    PhoneService.broadcast.$off(PhoneService.events.phone_kebab_response);
    PhoneService.broadcast.$once(PhoneService.events.phone_kebab_response, this.response.bind(this));
    PhoneService.send(PhoneService.events.phone_kebab_show, this.options);
  }

  response(event) {
    // Check that response is valid
    if (event.event === PhoneService.events.phone_kebab_response && event.data != null) {
      //
      // Perform action if index is in range
      if (typeof event.data.selectedIndex === 'number'
      && event.data.selectedIndex >= 0
      && event.data.selectedIndex < this.options.length) {
        const selectedOption = this.options[event.data.selectedIndex];
        if (selectedOption) {
          if (typeof selectedOption.action === 'function' && selectedOption.action != null) {
            selectedOption.action();
          } else if (typeof selectedOption.action === 'string' && selectedOption.action != null) {
            window.dispatchEvent(new Event(selectedOption.action));
          }
        }
      }

      // Clear
      this.options = [];
    }
  }
}

export default PhoneKebabMenu;
