import DeviceModel from '@/singletons/device.state.singleton';
import { getDevices, getDevicesCount } from '@/util/api';

class DeviceService {
  static async fetchDevices() {
    const order = DeviceModel.orderDesc ? 'DESC' : 'ASC';

    DeviceModel.devices = null;

    if (!DeviceModel.selectedTypeFilters.length
      || !DeviceModel.selectedSessionFilters.length
      || !DeviceModel.selectedHeartbeatFilters.length) {
      setTimeout(() => { DeviceModel.devices = []; }, 1000);
      return;
    }

    const { sessionFrom, sessionTo } = DeviceModel.getSessionTimestamps();

    const { heartbeatFrom, heartbeatTo } = DeviceModel.getHeartbeatTimestamps();

    const params = {
      limit: DeviceModel.perPage,
      search: DeviceModel.searchPhrase,
      page: DeviceModel.currentPage,
      orderBy: DeviceModel.orderBy,
      order,
      types: DeviceModel.selectedTypeFilters,
      sessionFrom,
      sessionTo,
      heartbeatTo,
      heartbeatFrom,
    };
    try {
      const response = await getDevices(params);
      DeviceModel.devices = response.data;
    } catch {
      DeviceModel.devices = [];
    }
  }

  static async fetchDeviceCount() {
    try {
      const { sessionFrom, sessionTo } = DeviceModel.getSessionTimestamps();

      const { heartbeatFrom, heartbeatTo } = DeviceModel.getHeartbeatTimestamps();

      const params = {
        search: DeviceModel.searchPhrase,
        page: DeviceModel.currentPage,
        types: DeviceModel.selectedTypeFilters,
        sessionFrom,
        sessionTo,
        heartbeatTo,
        heartbeatFrom,
      };

      const response = await getDevicesCount(params);
      DeviceModel.numberOfDevices = response.data;
    } catch {
      DeviceModel.numberOfDevices = 0;
    }
  }

  static async fetchFullDeviceCount() {
    try {
      const response = await getDevicesCount();
      DeviceModel.numberOfTotalDevices = response.data;
    } catch {
      DeviceModel.numberOfTotalDevices = 0;
    }
  }
}

export default DeviceService;
