<template>
  <div>
    <!-- TODO: This will be totally reworked -->
    <Card class="tab no-padding">
      <div class="sync-row margin margin-medium-large margin-bottom">
        <p>
          {{ $t('Components.Admin.esync_ready_status') }}
        </p>
        <div class="ready-indicator">
          <Label
            :status="getStatus.status"
            :text="getStatus.text"
          />
          <icon
            v-if="!syncRunning && !syncing && !active"
            id="tooltip-target-language"
            v-b-tooltip.hover="{ placement:'bottom-left', title: $t('Components.Admin.esync_status_tooltip') , customClass: 'tooltip bottom'}"
            icon="question-circle"
            class="chevron settings_icon--right "
          />
        </div>
      </div>
      <div
        v-if="active"
        class="sync-row margin margin-medium-large margin-bottom"
      >
        <p>
          {{ $t('Components.Admin.esync_restart_sync') }}
        </p>
        <Button
          variant="pastel-primary"
          :size="'tiny'"
          class="refresh-button"
          @click="restartSync()"
        >
          <svg-icon
            class="refresh-icon"
            src="/icons/refresh.svg"
          />
        </Button>
      </div>
      <div
        v-if="active"
        class="sync-row"
      >
        <p>
          {{ $t('Components.Admin.show_debug') }}
        </p>
        <span class="custom-area">
          <BFormCheckbox
            v-model="EsyncController.debug"
            switch
            @change="updateDebug"
          />
        </span>
      </div>
      <EsyncDebugLog
        v-if="EsyncController.debug"
        :logs="EsyncController.debugLogs"
        class="margin margin-top margin-medium"
      />
    </Card>
    <Card
      v-if="active"
      position="bottom"
    >
      <Button
        v-if="syncing"
        variant="danger"
        style="margin:auto;"
        @click="stopSync"
      >
        {{ $t('Components.Admin.esync_stop') }}
      </Button>

      <Button
        v-else
        variant="success"
        style="margin:auto;"
        @click="startSync"
      >
        {{ $t('Components.Admin.esync_start') }}
      </Button>
    </Card>
  </div>
</template>

<script>

/* Components */
import { VBTooltip, BFormCheckbox } from 'bootstrap-vue';
import Card from '@/components/card/card.vue';
import Label from '@/components/label.vue';
import Button from '@/components/button/button.vue';
import EsyncDebugLog from './esync.debug.log.vue';

/* Controllers */
import EsyncController from './esync.controller';

export default {
  components: {
    Card,
    Label,
    Button,
    BFormCheckbox,
    EsyncDebugLog,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      EsyncController,
    };
  },
  computed: {
    active() {
      return EsyncController.settings?.active;
    },
    /* Company is syncing */
    syncing() {
      return EsyncController.settings?.syncing_company;
    },
    /* Sync instance is up */
    syncRunning() {
      return EsyncController.syncRunning;
    },
    getStatus() {
      // TODO: Remove false when status in db indicates active,inactive,error for sync
      if (this.active && this.syncing && this.syncRunning) return { status: 'success', text: this.$t('Components.Admin.syncing') };
      if (this.active && this.syncRunning) return { status: 'warning', text: this.$t('Components.Admin.not_syncing_company') };
      if (this.active) return { status: 'danger', text: this.$t('Components.Admin.sync_inactive') };
      return { status: 'warning', text: this.$t('Components.Admin.not_ready') };
    },
  },
  async created() {
    await EsyncController.finnishedLoading;
    await EsyncController.getEsyncSettings();
    // Start intervals
    EsyncController.stopOrStartLogInterval(true);
    // EsyncController.syncHealthCheck(true);
  },
  destroyed() {
    // Stop intervals
    EsyncController.stopOrStartLogInterval(false);
    // EsyncController.syncHealthCheck(false);
  },
  methods: {
    async startSync() {
      await EsyncController.startSync();
    },
    async stopSync() {
      await EsyncController.stopSync();
    },
    async restartSync() {
      await EsyncController.restartSync();
    },
    async updateDebug() {
      EsyncController.stopOrStartLogInterval(EsyncController.debug);
    },
  },
};

</script>

<style lang="scss">
// TODO: Remove this when status in db indicates active,inactive,error for sync
.sync-action-buttons{
  display: flex;
  justify-content: space-between;
}
.sync-row{
  display: flex;
  justify-content: space-between;
  p{
    margin: auto 0;
  }
  .ready-indicator{
    display: flex;
    gap: 0.3rem;
  }
}
.refresh-button{
  padding: 0.3rem 0.5rem !important;
  .refresh-icon{
    fill: var(--primary-color);
    svg{
      width: 1rem;
      height: 1rem;
    }
  }
}

</style>
