<template>
  <div>
    <Header
      :back="true"
      :route="'/admin'"
      class="flex"
      :text="$t('Components.Sidebar.Admin')"
      :title="$t('Components.Admin.Device.devices')"
    >
      <h5 class="header-title">
        {{ $t('Components.Admin.Device.devices') }}
      </h5>
    </Header>
    <div class="scrollable">
      <Card position="fill">
        <div class="top-bar-actions margin margin-bottom margin-medium">
          <b-form-input
            v-model.lazy="DeviceModel.searchPhrase"
            class="search-bar"
            type="search"
            :trim="true"
            debounce="500"
            :placeholder="$t('Components.Admin.placeholder_search')"
            @update="updateData()"
          />

          <Button
            class="action-btn round"
            :size="'tiny'"
            :disabled="!DeviceModel.devices"
            @click="updateData()"
          >
            <svg-icon src="/icons/refresh.svg" />
          </Button>
        </div>
        <div class="filter_group-device">
          <CheckboxGroup
            v-model="DeviceModel.selectedTypeFilters"
            :options="optionsTypes"
            :header="headerTypes"
            @change="updateData()"
          />
        </div>
        <div class="filter_group-device">
          <CheckboxGroup
            v-model="DeviceModel.selectedSessionFilters"
            :options="optionsSessions"
            :header="headerSessions"
            @change="updateData()"
          />
        </div>
        <div class="filter_group-device">
          <CheckboxGroup
            v-model="DeviceModel.selectedHeartbeatFilters"
            :options="optionsHeartbeats"
            :header="headerHeartbeats"
            @change="updateData()"
          />
        </div>
        <hr>
        <div class="device_count-container">
          <p> {{ filterDeviceCountString }} </p>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import { BFormInput } from 'bootstrap-vue';
import Header from '@/components/headers/header.vue';
import Card from '@/components/card/card.vue';
import DeviceModel from '@/singletons/device.state.singleton';
import DeviceService from '@/Services/Device/device.service';
import Button from '@/components/button/button.vue';
import CheckboxGroup from '@/components/selections/checkbox.group.vue';

export default {
  components: {
    Header,
    CheckboxGroup,
    BFormInput,
    Button,
    Card,
  },
  data() {
    return {

      DeviceModel,

      headerTypes: { title: this.$t('Components.Admin.Device.device_type'), icon: '/icons/wifi.svg' },
      optionsTypes: [
        { value: 'motion', title: this.$t('Components.Admin.Device.motion') },
        { value: 'presence', title: this.$t('Components.Admin.Device.presence') },
        { value: 'desk', title: this.$t('Components.Admin.Device.desk_connect') },
        { value: 'signal', title: this.$t('Components.Admin.Device.signal') },
        { value: 'lock', title: this.$t('Components.Admin.Device.lock') },
      ],

      headerSessions: { title: this.$t('Components.Admin.Device.session'), icon: '/icons/clock_filled.svg' },
      optionsSessions: [
        { value: 'active', title: this.$t('Components.Admin.Device.active') },
        { value: 'active today', title: this.$t('Components.Admin.Device.active_today') },
        { value: 'no recent session', title: this.$t('Components.Admin.Device.no_recent_session') },
      ],

      headerHeartbeats: { title: this.$t('Components.Admin.Device.heartbeat'), icon: '/icons/heart-solid.svg' },
      optionsHeartbeats: [
        { value: 'last 30', title: this.$t('Components.Admin.Device.last_30') },
        { value: 'no recent heartbeat', title: this.$t('Components.Admin.Device.no_recent') },
      ],

      debounceTimer: null,
    };
  },
  computed: {
    filterDeviceCountString() {
      return `
        ${this.$t('Components.Admin.Device.showing')} ${DeviceModel.numberOfDevices}
        ${this.$t('Components.Admin.Device.of')} ${DeviceModel.numberOfTotalDevices}
        ${this.$t('Components.Admin.Device.total_devices')} `;
    },
  },
  methods: {
    updateData() {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = null;

      this.debounceTimer = setTimeout(() => {
        DeviceModel.currentPage = 1;
        DeviceService.fetchDeviceCount();
        DeviceService.fetchDevices();
      }, 250);
    },
  },
};

</script>

<style lang="scss">
.filter_group-device{
  margin-top: 1rem;
}
input.is-valid ~ .custom-control-label{
  color: var(--primary-font-color) !important;
}
.device_count-container{
  display: flex;
  flex-direction: column;
  margin: auto;
  *{
    display: flex;
    margin: auto;
  }
}
.top-bar-actions {
  background-color: white;
  width: 100%;
  max-width: 1800px;
  display: flex;
  justify-content: space-between;
  .search-bar {
    width: 20rem;
  }
  .action-btn {
    margin: auto 0;
    fill: white;
    height: 2.4rem;
    line-height: 1rem;
    &.sync-all {
      margin-left: auto;
    }
    &.round {
      fill: black;
      background-color: white;
      color: var(--primary-font-color);
      border: 1px solid var(--border-color);
      padding: 0.2rem;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      }
    }
}

</style>
