<template>
  <div
    class="flex margin margin-top"
  >
    <PersonAvatar
      :src="avatarUrl"
      height="50px"
      width="50px"
      :border="false"
      :user="userComputed"
      class="margin margin-right"
    />
    <p class="margin margin-small margin-top">
      {{ name }}
    </p>
  </div>
</template>

<script>
import PersonAvatar from '@/components/globals/Person/PersonAvatar.vue';
import UserService from '@/Services/User/user.service';

export default {
  components: {
    PersonAvatar,
  },
  props: {
    attendee: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      user: null,
    };
  },
  computed: {
    avatarUrl() {
      return (this.userComputed?.AvatarUrl || '/images/default_avatar.png');
    },
    name() {
      return (this.userComputed?.Name || this.$t('Views.Book.guest'));
    },
    userComputed() {
      return this.user;
    },
  },
  async mounted() {
    try {
      if (this?.attendee?.Username) {
        this.user = await UserService.getUserByUsername(this?.attendee?.Username);
      }
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style>

</style>
