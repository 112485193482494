/* eslint-disable class-methods-use-this */
import CompanyState from '@/singletons/company.state.singleton';

/* API */
import {
  getSyncedCompany, getSyncedUsers, /* setDebugMode, */ getEsyncSettings,
  setEsyncSettings, startSync, stopSync, restartSync, getSyncStatus,
  getSyncedCompanyLog,
} from '@/util/api';

// TODO: this should be an esync service instead.
class EsyncController {
  constructor() {
    this.syncRunning = false;
    this.debug = false;
    this.tenantId = null;
    this.debugLogs = [];
    this.interval = null;
    this.healthInterval = false;

    this.users = [];

    // this.hasLoadedOnce = false;
    this.finnishedLoading = false;
    this.isLoading = false;
  }

  destroy() {
    this.syncRunning = false;
    this.debug = false;
    this.debugLogs = [];
    this.tenantId = null;
    this.interval = null;

    this.users = [];

    // this.hasLoadedOnce = false;
    this.finnishedLoading = false;
    this.isLoading = false;
  }

  async init() {
    this.isLoading = true;
    console.log(CompanyState.realm);
    this.finnishedLoading = await Promise.all([
      CompanyState.finnishedLoading,
      this.getSyncedCompany(),
      this.getSyncedUsers(),
      this.getEsyncSettings(),
      this.getHealthStatus(),
      // this.getSyncStatus(),
    ]);

    this.isLoading = false;
  }

  // TODO: remove when sockets have been implemented
  async stopOrStartLogInterval(start = true) {
    if (!start) {
      clearInterval(this.debugInterval);
    } else {
      this.debugInterval = setInterval(() => {
        if (this.debug) this.getSyncedCompanyLog(CompanyState.realm);
      }, 1000);
    }
  }

  async syncHealthCheck(start = true) {
    if (!start) {
      clearInterval(this.healthInterval);
    } else {
      this.healthInterval = setInterval(async () => {
        await this.getHealthStatus();
        await this.getEsyncSettings();
      }, 5000);
    }
  }

  async getSyncedCompanyLog() {
    const { data } = await getSyncedCompanyLog(CompanyState.realm);
    this.debugLogs = data;
  }

  async getSyncedCompany() {
    const { data: info } = await getSyncedCompany(CompanyState.realm);
    console.log(CompanyState.realm);
    const { debug } = info;
    this.debug = debug;
  }

  async getSyncStatus() {
    const { data } = await getSyncStatus(CompanyState.realm);
    const { running } = data;
    this.running = running;
  }

  async getSyncedUsers() {
    const { data: users } = await getSyncedUsers(CompanyState.realm);
    this.users = users;
  }

  async getEsyncSettings() {
    const { data: settings } = await getEsyncSettings(CompanyState.realm);
    this.settings = settings;
  }

  // eslint-disable-next-line class-methods-use-this
  async setEsyncSettings(settings) {
    try {
      await setEsyncSettings(CompanyState.realm, settings);
    } catch (e) {
      console.log(e);
    }
  }

  async getHealthStatus() {
    const { data } = await getSyncStatus(CompanyState.realm);
    const { running } = data;
    this.syncRunning = running;
  }

  async startSync() {
    await startSync(CompanyState.realm);
    await this.getEsyncSettings();
  }

  async stopSync() {
    await stopSync(CompanyState.realm);
    await this.getEsyncSettings();
  }

  async restartSync() {
    await restartSync(CompanyState.realm);
    await this.getEsyncSettings();
  }

  // async setDebugMode(active) {
  //   try {
  //     const debugOn = { mode: active ? 1 : 0 };
  //     const { data } = await setDebugMode(CompanyState.realm, debugOn);
  //     const { mode } = data;
  //     this.debug = !!mode;
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }
}

export default new EsyncController();
