<template>
  <div>
    <Header
      class="flex"
      :title="$t('Components.AgendaHeader.title')"
    >
      <h5 class="header-title">
        {{ $t('Components.AgendaHeader.title') }}
      </h5>
    </Header>
    <div class="scrollable">
      <Card>
        <WeeklyDateSelector
          :week-length="WeekState.weekLength"
          :week-start="WeekState.date"
          :booking-notifications="bookingNotifications"
          @date-selected="dateChanged"
          @week-changed="weekChanged"
        />
      </Card>
      <Card
        v-if="bookings.length"
        position="bottom"
        :header="$t('Views.Calendar.your_bookings')"
      >
        <CalendarResourceListItem
          v-for="(booking, index) in bookings"
          :key="booking.Bid"
          :class="`${index + 1 < bookings.length ? 'margin margin-bottom margin-small-2' : ''}`"
          :booking="booking"
        />
      </Card>
      <Card
        v-if="showConnections"
        :position="`bottom ${bookings?.length ? 'no-padding-top' : ''}`"
        :header="$t('Views.Calendar.your_connections')"
      >
        <CalenderConnectionListItem
          v-for="conn in connections"
          :key="conn.Zid"
          class="margin margin-bottom margin-small-2"
          :connection="conn"
        />
      </Card>
      <Card v-if="!showConnections && !bookings.length">
        <CalendarNoBookingItem />
      </Card>
    </div>
  </div>
</template>

<script>
import Card from '@/components/card/card.vue';
import Header from '@/components/headers/header.vue';
import ExtendedDate from '@/classes/extended.date.class';
import UserState from '@/singletons/user.state.singleton';
import UserService from '@/Services/User/user.service';
import CompanyState from '@/singletons/company.state.singleton';
import WeekState from '@/singletons/week.state.singleton';
import WeeklyDateSelector from '@/components/weeklydateselector/weekly.dateselector.vue';

/** Services */
import BookingService from '@/Services/Bookings/bookings.service';
import { getBookingNotifications } from '@/functions/date.notification.functions';
import ZoneState from '@/singletons/zone.state.singleton';
import CalendarResourceListItem from './components/calendar.resource.list.item.vue';
import CalendarNoBookingItem from './components/calendar.nobooking.item.vue';
import CalenderConnectionListItem from './components/calendar.connection.list.item.vue';
import QueryMixin from '../../mixins/query.mixin';

export default {
  components: {
    Card,
    Header,
    WeeklyDateSelector,
    CalendarResourceListItem,
    CalenderConnectionListItem,
    CalendarNoBookingItem,
  },

  mixins: [QueryMixin],
  data() {
    const STATE = {
      INITIAL: 0,
      UPDATING: 1,
      READY: 2,
    };
    return {
      UserService,
      UserState,
      WeekState,
      viewState: STATE.INITIAL,
      isLoading: true,
      loaded: false,
      bookingNotifications: [],
    };
  },
  computed: {
    showConnections() {
      return this.connections.length && WeekState.date.isoDateString() === this.today;
    },
    today() {
      return new ExtendedDate().isoDateString();
    },
    bookings() {
      const activeDate = new ExtendedDate(WeekState.date);
      const now = new ExtendedDate();
      const bookings = UserState.bookings
        .filter(BookingService.isSameDay(activeDate, now))
        .sort(BookingService.sortBookingsByTime);
      return bookings;
    },
    connections() {
      return ZoneState?.realmState.filter((user) => user?.Username === UserState.user?.Username) || [];
    },
  },
  async created() {
    if (UserState.user.AbstractUser) {
      this.$router.replace({ name: 'search' });
    } else {
      const { date } = this.$route.query;
      let checkedDate = new ExtendedDate();
      if (date) {
        checkedDate = ExtendedDate.createDateFromQuery(date);
      }
      await this.weekChanged(checkedDate);
      await CompanyState.finnishedLoading;
      this.loaded = true;
    }
  },

  methods: {
    async weekChanged(date) {
      WeekState.changeDate(date);
      this.replaceQuery({ date: WeekState.date.getDateString() });
      this.bookingNotifications = [];
      await Promise.all([
        UserService.fetchUserBookings(WeekState.weekStart.getTime(), WeekState.weekEnd.getTime()).then(() => {
          this.bookingNotifications = getBookingNotifications(UserState.bookings, WeekState.weekLength);
        }),
      ]);
    },
    dateChanged(date) {
      WeekState.date = date;
      this.replaceQuery({ date: WeekState.date.getDateString() });
    },
  },
};
</script>
