<template>
  <div>
    <Card class="tab no-padding">
      <h6 class="title">
        {{ $t('Components.Admin.settings_general') }}
      </h6>
      <div class="list-item">
        <p>
          {{ $t('Components.Admin.esync_status_active') }}
        </p>
        <span class="custom-area">
          <BFormCheckbox
            v-model="settings.active"
            switch
          />
        </span>
      </div>

      <div class="list-item custom vertical">
        <p>
          {{ $t('Components.Admin.tenant_id') }}
        </p>
        <b-form-input
          v-model="settings.graph_tenantid"
          type="text"
          class="inputfield"
          :placeholder="$t('Components.Admin.tenant_id')"
        />
      </div>
      <!-- @update="updateTable()" -->

      <div class="list-item custom vertical">
        <p>
          {{ $t('Components.Admin.groups_include') }}
        </p>
        <InputFieldOptions
          v-model="settings.graph_user_groups_to_include"
          class="inputfield"
        />
      </div>
      <!-- </div> -->
    </Card>
    <Card class="tab no-padding">
      <h6> {{ $t('Components.Admin.settings_domain') }} </h6>
      <div class="list-item">
        <p>
          {{ $t('Components.Admin.exclude_external_users') }}
        </p>
        <span class="custom-area">
          <BFormCheckbox
            v-model="settings.graph_exclude_external_users"
            switch
          />
        </span>
      </div>
      <div class="list-item custom vertical">
        <p>
          {{ $t('Components.Admin.domain') }}
        </p>
        <InputFieldOptions
          v-model="settings.graph_domain"
          class="inputfield"
        />
      </div>
      <div class="list-item custom vertical">
        <p>
          {{ $t('Components.Admin.exclude_mail_prefix') }}
        </p>
        <InputFieldOptions
          v-model="settings.graph_mail_prefixes_to_exclude"
          class="inputfield"
        />
      </div>
    </Card>
    <Card
      position="bottom"
    >
      <div class="flex">
        <Button
          variant="success"
          style="margin:auto;"
          @click="saveSettings()"
        >
          {{ $t('Components.Admin.save') }}
        </Button>
      </div>
    </Card>
  </div>
</template>

<script>

import Card from '@/components/card/card.vue';
import InputFieldOptions from '@/components/InputField/input.field.component.options.vue';
import Button from '@/components/button/button.vue';

import { BFormCheckbox, BFormInput } from 'bootstrap-vue';

import EsyncController from './esync.controller';

export default {
  components: {
    Card,
    BFormCheckbox,
    BFormInput,
    InputFieldOptions,
    Button,
  },
  data() {
    return {
      settings: {},
      EsyncController,
    };
  },
  async created() {
    // await EsyncController.getEsyncSettings();
    // console.log('SETTINGS: ', EsyncController.settings);
    /*
      active, graph_exclude_external_users,
      graph_mail_prefixes_to_exclude, graph_domain,
      graph_tenantid, graph_user_groups_to_include,
    */
    this.settings = EsyncController.settings;
    console.log(this.settings);
  },
  methods: {
    async saveSettings() {
      console.log('click');
      delete this.settings.syncing_company;
      await EsyncController.setEsyncSettings(this.settings);
    },
  },
};

</script>

<style lang="scss">

.tab {
  .title {
    margin-bottom: 1.5rem;
    margin-left: -5px;
  }
}

.list-item {
  &.custom {
    display: inline-flex;
    margin-top: 2rem;
  }

  &.vertical {
    flex-direction: column !important;
  }
  .inputfield {
    margin-top: 0.5rem;
    border-radius: 0.6rem;
  }
}

</style>
