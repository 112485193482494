<template>
  <div>
    <Header
      class="flex"
      :title="$t('Components.Sidebar.Admin')"
    >
      <h5 class="header-title">
        {{ $t('Components.Sidebar.Admin') }}
      </h5>
    </Header>
    <div class="scrollable">
      <Card>
        <router-link
          v-if="CompanyState.hasLoadedOnce && UserService.hasAllRoles(['role_issue_resolve'])"
          to="/admin/issue-tracker"
        >
          <div class="settings_link--container">
            <div class="content-container">
              <RoundResourceIcon
                :src="'/icons/issue_symbol.svg'"
                type="settings reverse"
              />
              <h6 class="setting_link--text">
                {{ $t('Components.Admin.Issue_Reports.issue_reports') }}
              </h6>
            </div>
            <div class="custom-area">
              <icon
                icon="chevron-right"
                class="chevron settings_icon--right "
              />
            </div>
          </div>
        </router-link>
        <router-link
          v-if="false"
          to="/admin/esync"
        >
          <div class="settings_link--container">
            <div class="content-container">
              <RoundResourceIcon
                :src="'/icons/sync.svg'"
                type="settings reverse"
              />
              <h6 class="setting_link--text">
                {{ $t('Components.Admin.esync') }}
              </h6>
            </div>
            <div class="custom-area">
              <span class="pre-info settings_link--text"> {{ esyncStatus }} </span>
              <icon
                icon="chevron-right"
                class="chevron settings_icon--right "
              />
            </div>
          </div>
        </router-link>
        <router-link
          v-if="CompanyState.hasLoadedOnce && UserService.hasAllRoles(['role_device_debug'])"
          to="/admin/devices"
        >
          <div class="settings_link--container">
            <div class="content-container">
              <RoundResourceIcon
                :src="'/icons/wifi.svg'"
                type="settings reverse"
              />
              <h6 class="setting_link--text">
                {{ $t('Components.Admin.Device.devices') }}
              </h6>
            </div>
            <div class="custom-area">
              <icon
                icon="chevron-right"
                class="chevron settings_icon--right "
              />
            </div>
          </div>
        </router-link>
      </Card>
    </div>
  </div>
</template>

<script>
import PhoneService from '@/Services/Phone/phone.service';
import Header from '@/components/headers/header.vue';
import Card from '@/components/card/card.vue';
import RoundResourceIcon from '@/components/icons/round.resource.icon.vue';
import CompanyState from '@/singletons/company.state.singleton';
import UserService from '@/Services/User/user.service';
import EsyncController from './esync/esync.controller';

export default {
  components: {
    Header,
    Card,
    RoundResourceIcon,
  },
  data() {
    return {
      EsyncController,
      PhoneService,
      CompanyState,
      UserService,
    };
  },
  computed: {
    esyncStatus() {
      return this.EsyncController.active ? this.$t('Components.Admin.esync_status_active') : this.$t('Components.Admin.esync_status_inactive');
    },
  },
};

</script>

<style lang="scss" scoped>

  // .content {
  //   // height: fit-content;
  //   // display: flex;

  //   .list-items {
  //     display: inline-flex;
  //     line-height: 2.5rem;
  //     width: 100%;
  //   }

  //   .chevron {
  //     color: var(--secondary-font-color);
  //   }

  //   .icon-wrapper {
  //     vertical-align: middle;
  //     justify-content: center;
  //     align-content: center;
  //     align-items: center;
  //     width: 2rem;
  //     height: 2rem;
  //     fill: var(--primary-color);
  //     border: 1px solid var(--primary-color);
  //     border-radius: 50%;
  //     margin-right: 1rem;
  //   }

  // }

  .settings_link--container {
  margin: 1rem 0;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  &:hover{
    text-decoration: none !important;
    cursor: pointer;
  }
  .content-container{
    display: flex;
    gap:1rem;
  }
}
.setting_link--text{
  margin-top: auto;
  margin-bottom: auto;
}

.settings_icon--right {
  margin: auto 0;
  font-size: 1.3rem;
}

.custom-area {
  display: flex;
  align-items: center;
  gap: 1rem;
}

</style>
