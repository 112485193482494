<template>
  <div
    class="calendar-list__wrapper "
  >
    <div class="margin margin-right small-padd">
      <RoundResourceIcon
        :src="getResourceIcon(getLocationByZoneId(connection.Zid))"
        type="connection"
      />
    </div>
    <div
      class="info"
      @click="listItemClicked()"
    >
      <p class="normal-semi">
        {{ getConnectionTitle(getLocationByZoneId(connection.Zid)?.Type) }}
      </p>
      <div class="booking-time-location">
        <p class="small-regular">
          {{ getZoneNameByZid(connection.Zid) }},  {{ getConnectionTimeString(connection) }}
        </p>
      </div>
    </div>
    <div
      class="end-icons-plan padding padding-small padding-top"
    >
      <div @click="showOnMap()">
        <svg-icon
          class="end-icon"
          src="/icons/map_navigation_pin.svg"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ExtendedDate from '@/classes/extended.date.class';
import CompanyService from '@/Services/Company/company.service';
import CompanyState from '@/singletons/company.state.singleton';
import RoundResourceIcon from '@/components/icons/round.resource.icon.vue';
import PhoneService from '@/Services/Phone/phone.service';
import LocationController from '@/views/location.selection/location.selection.controller';
import UserState from '@/singletons/user.state.singleton';
import QueryMixin from '../../../mixins/query.mixin';
import mapController from '../../../classes/map/map.controller';

export default {
  components: {
    RoundResourceIcon,
  },
  mixins: [QueryMixin],
  props: {
    connection: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      CompanyState,
    };
  },
  methods: {
    getLocationByZoneId(zid) {
      return CompanyService.getZoneByZid(zid);
    },
    getConnectionTitle(type) {
      switch (type) {
        case 'DESK': return this.$t('Components.Search.connected_to_desk');
        case 'LOCKER': return this.$t('Components.Search.connected_to_lock');
        default: return this.$t('Components.Search.connected_to_connection');
      }
    },
    getResourceIcon(connection) {
      return CompanyService.getResourceIconFromType(connection?.ZoneType || connection?.Type);
    },
    getConnectionTimeString(conn) {
      const from = new ExtendedDate(conn.Created);
      return this.$t('Components.Search.search_details_from', { time: from.localeTimeString() });
    },
    getZoneNameByZid(zid) {
      return CompanyState.zones.find((zone) => zid === zone.Zid)?.Name;
    },
    async listItemClicked() {
      if (PhoneService.isInPhoneApp()) {
        PhoneService.send(PhoneService.events.phone_device_connect, { zid: this.connection.Zid });
      } else {
        this.showOnMap();
      }
    },
    async showOnMap() {
      const currentZone = CompanyState?.zones.find((zone) => zone?.Zid === this?.connection?.Zid);
      const floor = CompanyState.zones.find((parent) => parent.Zid === currentZone.ParentZone);
      if (floor.Zid !== UserState.selectedLocation.Zid) {
        await LocationController.setLocationByFloorZid(floor.Zid);
      }
      if (PhoneService.isInPhoneApp()) {
        this.$router.push({ name: 'map', query: { zid: this?.connection?.Zid } });
      } else {
        this.replaceQuery({ zid: this?.connection?.Zid });
        mapController.showMapMarker(currentZone, true);
      }
    },
  },
};
</script>
<style lang="scss">
  .calendar-list__wrapper {
    display: flex;
    flex-grow: 1;
  }
  .small-padd {
    padding-top: 0.2rem;
  }
  .calendar-list__wrapper.last-item {
    display: flex;
    flex-grow: 1;
    margin-bottom: 0rem;
  }
  .info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-size: 0.8rem;
    cursor: pointer;
    .booking-time-location{
      display: flex;
      flex-direction: row;
      svg{
        width: 1.2rem;
        height: 1.2rem;
        padding-bottom: 0.2rem;
        fill: var(--primary-color);
      }
    }
  }
  .end-icons-plan {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    svg {
        fill: var(--primary-color);
    }
    .issue-icon {
      svg {
        width: 1.3rem;
        height: 1.3rem;
        fill: #c90c50;
      }
    }
    .end-icon {
      cursor: pointer;
      svg {
        margin-right: 0;
      }
    }
  }
</style>
