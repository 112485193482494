<template>
  <div
    class="status"
    :class="status"
  >
    <p class="tiny-regular">
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    /* success, warning, danger, disabled */
    status: {
      required: true,
      type: String,
    },
    text: {
      required: true,
      type: String,
    },
  },

};
</script>

<style lang="scss" scoped>
 .status {
      display: flex;
      min-width: 100px;
      min-height: 30px;
      padding: 0 6px 0 6px;
      border-radius: 0.5rem;

      p {
        font-weight: 600;
        text-align: center;
        margin: auto;
      }

      &.success {
        background-color: var(--color-pastel-resource-free);
        p {
          color: var(--primary-color);
        }
      }
      &.warning {
        background-color: var(--color-pastel-resource-away);
        p {
          color: var(--away-color);
        }
      }
      &.danger {
        background-color: var(--color-pastel-resource-occupied);
        p {
          color: var(--occupied-color);
        }
      }
      &.disabled {
        background-color: rgba(204, 204, 204, 0.3); // sue me
        p {
          color: var(--disabled);
        }
      }
    }
</style>
