<template>
  <div>
    <!-- TODO: 18n -->
    <Header
      class="flex"
      :back="true"
      :text="$t('Views.Calendar.edit_booking')"
      :route="backRoute"
      :title="booking.Subject"
    >
      <h5 class="header-title">
        {{ booking.Subject }}
      </h5>
    </Header>
    <div class="scrollable">
      <Card>
        <WeeklyDateSelector
          :week-length="WeekState.weekLength"
          :week-start="WeekState.date"
          :booking-notifications="bookingNotifications"
          @date-selected="dateChanged"
          @week-changed="weekChanged"
        />
      </Card>
      <Card>
        <div
          v-if="!TimepickerController.hasLoadedOnce"
          class="spinner-medium"
        />
        <Timepicker
          :start-date="fromDate"
          :end-date="untilDate"
          :zid="booking.Zid"
          :edit-bid="booking.Bid"
        />
      </Card>
      <Card position="bottom">
        <Button
          class="margin margin-left margin-right margin-large"
          :disabled="btnDisabled"
          @click="editClicked"
        >
          {{ $t('Components.BookingDetails.edit') }}
        </Button>
      </Card>
    </div>
  </div>
</template>

<script>

import Card from '@/components/card/card.vue';
import Header from '@/components/headers/header.vue';
import Button from '@/components/button/button.vue';

import Timepicker from '@/components/timepicker/timepicker.vue';
import WeeklyDateSelector from '@/components/weeklydateselector/weekly.dateselector.vue';
import TimepickerController from '@/components/timepicker/timepicker.controller';

import UserState from '@/singletons/user.state.singleton';
import WeekState from '@/singletons/week.state.singleton';
import ExtendedDate from '@/classes/extended.date.class';

import BookingService from '@/Services/Bookings/bookings.service';
import UserService from '@/Services/User/user.service';
import bookingTemplate from '@/views/book/template.booking';
import { getBookingNotifications } from '@/functions/date.notification.functions';

export default {
  components: {
    Card,
    Header,
    Button,
    Timepicker,
    WeeklyDateSelector,
  },
  data() {
    return {
      activeDate: undefined,
      bookingId: parseInt(this.$route.params.bid, 10),
      from: new ExtendedDate(),
      bookingTemplate,
      TimepickerController,
      WeekState,
      UserState,
      userBookings: [],
      bookingsWeek: [],
      bookingNotifications: [],
    };
  },
  computed: {
    timepickerDate() {
      return this.activeDate;
    },
    fromDate() {
      return new ExtendedDate(this.booking.From) || this.from;
    },
    untilDate() {
      const until = new ExtendedDate(this.booking.Until) || undefined;
      return until;
    },
    booking() {
      const bookingId = parseInt(this.$route.params.bid, 10);
      return this.userBookings.find((b) => b.Bid === bookingId) || UserState.bookings.find((b) => b.Bid === bookingId);
    },
    btnDisabled() {
      return !TimepickerController.indexStart;
    },
    backRoute() {
      return `/calendar/booking/${this.$route.params.bid}?date=${this.$route.query.date}`;
    },
  },
  async created() {
    const { date } = this.$route.query;
    const checkedDate = date && date !== 'undefined' ? new ExtendedDate(date) : new ExtendedDate();
    await this.weekChanged(checkedDate);
    this.userBookings = await UserService.fetchUserBookingsForLocalUse(WeekState.weekStart.getTime(), WeekState.weekEnd.getTime());
    await BookingService.getBookingsOfDay(WeekState.date);
    this.activeDate = new ExtendedDate(WeekState.date);
  },
  destroyed() {
    if (!this.$route.path.includes(this.bookingId)) {
      this.resetBookingData();
    }
  },
  methods: {
    resetBookingData() {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key] of Object.entries(bookingTemplate)) {
        bookingTemplate[key] = null;
      }
    },
    async editClicked() {
      let end;
      bookingTemplate.From = new Date(WeekState.date).setHours(0, 0, 0, TimepickerController.getTimestampFromIndex(TimepickerController.indexStart));
      if (TimepickerController.indexEnd) {
        end = new Date(WeekState.date).setHours(0, 0, 0, TimepickerController.getTimestampFromIndex(TimepickerController.indexEnd) + 1000 * 60 * 30);
      } else if (TimepickerController.indexStart) {
        end = new Date(WeekState.date).setHours(0, 0, 0, TimepickerController.getTimestampFromIndex(TimepickerController.indexStart) + 1000 * 60 * 30);
      }

      bookingTemplate.Until = end;

      this.$router.push(`/calendar/booking/${this.$route.params.bid}?date=${this.$route.query.date}`);
    },
    async dateChanged(date) {
      WeekState.date = date;
      this.activeDate = WeekState.date;

      let end;
      // If new date is same as original booking, preselect the booking timeslots
      if (new ExtendedDate(this.booking.From).setHours(0, 0, 0, 0) === date.setHours(0, 0, 0, 0)) {
        end = new ExtendedDate(this.booking.Until);
        this.activeDate = new ExtendedDate(this.booking.From);
      }
      await TimepickerController.init(this.activeDate, end, this.booking.Zid, 16, false, this.booking.Bid);
    },
    async weekChanged(date) {
      WeekState.changeWeek(date);
      this.bookingNotifications = [];
      await Promise.all([
        UserService.fetchUserBookingsForLocalUse(WeekState.weekStart.getTime(), WeekState.weekEnd.getTime()).then((result) => {
          this.userBookings = result;
          this.bookingNotifications = getBookingNotifications(UserState.bookings, WeekState.weekLength);
        }),
        BookingService.getBookingsOfDay(date),
      ]);
      this.dateChanged(date);
    },
  },
};

</script>

<style>

</style>
