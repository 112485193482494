<template>
  <div
    class="input-content"
  >
    <div class="form-holder">
      <BFormInput
        v-model="inputValue"
        :type="'text'"
        :state="state"
        class="input-field"
      />
      <Button
        :size="'tiny'"
        class="add-btn"
        variant="pastel-primary"
        @click="addItem"
      >
        <svg-icon
          class="add-icon"
          src="/icons/add.svg"
        />
      </Button>
    </div>
    <div class="selections">
      <div
        v-for="item in allItems"
        :key="item"
        class="selection"
      >
        <p>
          {{ item }}
        </p>
        <div
          class="cancel-btn"
          @click="removeItem(item)"
        >
          <svg-icon src="/icons/close_icon.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { BFormInput } from 'bootstrap-vue';
import Button from '@/components/button/button.vue';

export default {
  components: {
    BFormInput,
    Button,
  },
  props: {
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      state: null,
      inputValue: '',
      items: this.value,
    };
  },
  computed: {
    allItems() {
      return this.items;
    },
  },
  methods: {
    addItem() {
      if (this.inputValue !== '' && this.items.indexOf(this.inputValue) === -1) {
        this.state = null;
        this.items.push(this.inputValue);
        this.inputValue = '';
        this.handleInput();
      } else if (this.items.inputValue !== -1) {
        this.state = false;
      }
    },
    removeItem(item) {
      const ix = this.items.indexOf(item);
      this.items.splice(ix, 1);
      this.handleInput();
    },
    handleInput() {
      this.$emit('input', this.items);
    },
  },
};

</script>

<style lang="scss">
  .input-field {
    border-radius: 0.6rem;
  }
  .input-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .form-holder {
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    .add-btn {
      width: 2.4rem;
      height: 2.4rem;
      padding: 0;
      .add-icon{
        svg{
          height: 1rem;
          width: 1rem;
          fill: var(--primary-color);
        }
      }
    }
  }

  .selections {
    gap: 0.5rem;
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    .selection {
      gap: 0.5rem;
      display: flex;
      padding: 0.3rem 0.5rem 0.3rem 0.8rem;
      background-color: var(--color-pastel-resource-free);

      p {
        color: var(--primary-color);
      }

      .cancel-btn {
        fill: var(--primary-color);
        margin-top: auto;
        margin-bottom: auto;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

</style>
