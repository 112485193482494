<template>
  <div>
    <Card
      position="no-padding-sides"
      margin-sides="no-margin-sides"
    >
      <div class="slot--details">
        <RoundResourceIcon
          :src="iconSrc || '/icons/default_space.svg'"
          type="resource reverse"
          class="margin margin-right"
        />
        <div class="margin margin-small margin-top overflow-hidden">
          <p class="normal-semi elipsis">
            {{ booking.Subject }}
          </p>
        </div>
      </div>
      <div class="slot--details">
        <RoundResourceIcon
          :src="'/icons/calendar_date_icon.svg'"
          type="resource reverse"
          class="margin margin-right"
        />
        <div>
          <p class="normal-semi">
            {{ date.getMonthDateNrString() }}
          </p>
          <p class="small-regular">
            {{ date.getWeekDayAndYearString() }}
          </p>
        </div>
      </div>
      <div class="slot--details">
        <RoundResourceIcon
          :src="'/icons/new_clock.svg'"
          type="resource reverse"
          class="margin margin-right"
        />
        <div class="margin margin-small margin-top">
          <p class="normal-semi">
            {{ bookingTime }}
          </p>
        </div>
      </div>
      <div class="slot--details">
        <RoundResourceIcon
          :src="'/icons/map_navigation_pin.svg'"
          type="resource reverse"
          class="margin margin-right"
        />
        <div class="slot--location__text overflow-hidden">
          <p class="normal-semi elipsis">
            {{ zoneName }}
          </p>
          <p class="small-regular elipsis">
            {{ company }} {{ floor.name }}
          </p>
        </div>
        <svg-icon
          v-if="needCheckIn"
          class="issue-icon"
          src="/icons/issue_symbol.svg"
        />
        <Kebab :options="options" />
      </div>
    </Card>
    <Card
      position="no-padding-sides"
      margin-sides="no-margin-sides"
    >
      <div class="avatar--wrapper margin margin-small margin-right margin-top margin-bottom">
        <PersonAvatar
          :src="avatarUrl"
          height="50px"
          width="50px"
          :border="false"
          :user="userComputed"
        />
        <div
          class="organizer--text margin margin-left overflow-hidden"
        >
          <p class="margin margin-small margin-top normal-semi elipsis">
            {{ name }}
          </p>
          <p class="small-regular">
            {{ $t('Components.BookingDetails.organizer') }}
          </p>
        </div>
      </div>
    </Card>
    <Card
      v-if="booking.Attendees.length"
      position="bottom no-padding-sides"
      margin-sides="no-margin-sides"
    >
      <p
        v-if="accepted.length"
        class="normal-semi"
      >
        {{ $t('Components.BookingTab.invitation_accepted') }} :
      </p>
      <Attendee
        v-for="(Attendee, index) in accepted"
        :key="index"
        :attendee="Attendee"
      />
      <p
        v-if="pending.length"
        class="margin margin-top normal-semi"
      >
        {{ $t('Components.BookingTab.invitation_pending') }} :
      </p>
      <Attendee
        v-for="(Attendee, index) in pending"
        :key="index"
        :attendee="Attendee"
      />
    </Card>
  </div>
</template>

<script>
import Card from '@/components/card/card.vue';
import RoundResourceIcon from '@/components/icons/round.resource.icon.vue';
import UserState from '@/singletons/user.state.singleton';
import CompanyState from '@/singletons/company.state.singleton';
import ExtendedDate from '@/classes/extended.date.class';
import PersonAvatar from '@/components/globals/Person/PersonAvatar.vue';
import Attendee from '@/views/calendar/components/calendar.attendee.vue';
import LocationSelectionController from '@/views/location.selection/location.selection.controller';
import Kebab from '@/components/kebab.menu.vue';
import BookingService from '@/Services/Bookings/bookings.service';
import UserService from '@/Services/User/user.service';
import mapController from '@/classes/map/map.controller';
import PhoneService from '@/Services/Phone/phone.service';

import QueryMixin from '../../mixins/query.mixin';

export default {
  components: {
    RoundResourceIcon,
    Card,
    PersonAvatar,
    Attendee,
    Kebab,
  },
  mixins: [QueryMixin],
  props: {
    booking: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      date: new ExtendedDate(this?.booking?.From),
      UserState,
      options: [],
      user: null,
    };
  },
  computed: {
    iconSrc() {
      return CompanyState.zoneTypes.find((type) => type.Constant === this?.booking?.ZoneType)?.Icon;
    },
    bookingTime() {
      return `${new ExtendedDate(this.booking.From).localeTimeString()} - ${new ExtendedDate(this.booking.Until).localeTimeString()}`;
    },
    zoneName() {
      return CompanyState.zones.find((zone) => zone.Zid === this?.booking?.Zid)?.Name;
    },
    zone() {
      return CompanyState.zones.find((zone) => zone.Zid === this?.booking?.Zid);
    },
    floor() {
      return CompanyState.zones.find((zone) => zone.Zid === this?.zone?.ParentZone);
    },
    company() {
      return LocationSelectionController.getItemModelByFloorId(this.zone?.ParentZone)?.parentName;
    },
    accepted() {
      return this.booking.Attendees.filter((attendee) => attendee.Status === 'ACCEPTED');
    },
    pending() {
      return this.booking.Attendees.filter((attendee) => attendee.Status === 'TENTATIVE' || attendee.Status === 'WAITING');
    },
    needCheckIn() {
      return BookingService.isCheckInNeededForBooking(this?.booking);
    },
    isOrganizer() {
      return (this.booking.Owner === UserState.username);
    },
    avatarUrl() {
      return (this.userComputed?.AvatarUrl || '/images/default_avatar.png');
    },
    name() {
      return (this.userComputed?.Name || this.$t('Views.Book.guest'));
    },
    userComputed() {
      return this.user;
    },
  },
  async mounted() {
    this.createOptions();
    try {
      if (this?.booking?.Owner) {
        this.user = await UserService.getUserByUsername(this?.booking?.Owner);
      }
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    async checkIn() {
      const response = await BookingService.checkIn(this.booking);
      if (response.status === 200) {
        this.booking.CheckIn = response.data.CheckIn;
      }
    },
    createOptions() {
      if (this.needCheckIn) {
        const option1 = {
          text: this.$t('Components.BookingTab.check_in'),
          isDanger: true,
          action: () => { this.checkIn(); },
        };
        this.options.push(option1);
      }
      const option2 = {
        text: this.$t('Components.BookingTab.show_on_map'),
        isDanger: false,
        action: async () => {
          if (this.floor.Zid !== UserState.selectedLocation.Zid) {
            await LocationSelectionController.setLocationByFloorZid(this.floor.Zid);
          }
          if (PhoneService.isInPhoneApp()) {
            this.$router.push({ name: 'map', query: { zid: this?.booking?.Zid } });
          } else {
            this.updateQuery({ zid: this?.booking?.Zid });
            mapController.showMapMarker(this.zone, true);
          }
        },
      };
      this.options.push(option2);
    },
  },
};
</script>

<style lang="scss">
  .slot--details {
    display: flex;
    flex-direction: row;
    margin-bottom: 1.2rem;
    .issue-icon {
      padding-top: 0.5rem;
      svg {
        width: 1.3rem;
        height: 1.3rem;
        fill: #c90c50;
      }
    }
  }
  .slot--location__text {
    flex-grow: 1;
  }
  .avatar--wrapper {
    display: flex;
    flex-direction: row;
  }
  .organizer--text {
    flex-direction: column;
  }
  p {
    margin-bottom: 0;
  }
</style>
