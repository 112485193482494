import { DateTime } from 'luxon';

const MOTION_FILTER = 'motion';
const PRESENCE_FILTER = 'presence';
const DESK_CONNECT_FILTER = 'desk';
const SIGNAL_FILTER = 'signal';
const LOCK_FILTER = 'lock';

const SESSION_ACTIVE_FILTER = 'active';
const SESSION_ACTIVE_TODAY_FILTER = 'active today';
const SESSION_NO_RECENT = 'no recent session';

const HEARTBEAT_LAST_30_FILTER = 'last 30';
const HEARTBEAT_NO_RECENT_FILTER = 'no recent heartbeat';

const MIN_30 = 1000 * 60 * 30;
const DAYS_1 = 1000 * 60 * 60 * 24 * 1;
class DeviceStateSingleton {
  constructor() {
    this.devices = null;

    this.numberOfDevices = 1;
    this.numberOfTotalDevices = 1;

    this.orderDesc = false;
    this.orderBy = null;
    this.currentPage = 1;
    this.perPage = 15;
    this.searchPhrase = '';

    this.selectedTypeFilters = [MOTION_FILTER, PRESENCE_FILTER, DESK_CONNECT_FILTER, SIGNAL_FILTER, LOCK_FILTER];
    this.selectedSessionFilters = [SESSION_ACTIVE_FILTER, SESSION_ACTIVE_TODAY_FILTER, SESSION_NO_RECENT];
    this.selectedHeartbeatFilters = [HEARTBEAT_LAST_30_FILTER, HEARTBEAT_NO_RECENT_FILTER];
  }

  getSessionTimestamps() {
    const now = DateTime.now().toMillis();
    const startOfTodayTime = DateTime.now().startOf('day').toMillis();

    const to = {
      [SESSION_ACTIVE_FILTER]: now + DAYS_1,
      [SESSION_ACTIVE_TODAY_FILTER]: now,
      [SESSION_NO_RECENT]: startOfTodayTime,
    };
    const from = {
      [SESSION_ACTIVE_FILTER]: now,
      [SESSION_ACTIVE_TODAY_FILTER]: startOfTodayTime,
      [SESSION_NO_RECENT]: 0,
    };

    const selectedToOptions = [];
    const selectedFromOptions = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const option of this.selectedSessionFilters) {
      selectedToOptions.push(to[option]);
      selectedFromOptions.push(from[option]);
    }

    const sessionFrom = DateTime.fromMillis(Math.min(...selectedFromOptions)).toJSDate().toISOString();
    const sessionTo = DateTime.fromMillis(Math.max(...selectedToOptions)).toJSDate().toISOString();

    return { sessionFrom, sessionTo };
  }

  getHeartbeatTimestamps() {
    const now = DateTime.now().toMillis();

    const to = {
      [HEARTBEAT_LAST_30_FILTER]: now + MIN_30,
      [HEARTBEAT_NO_RECENT_FILTER]: now - MIN_30,
    };
    const from = {
      [HEARTBEAT_LAST_30_FILTER]: now - MIN_30,
      [HEARTBEAT_NO_RECENT_FILTER]: 0,
    };

    const selectedToOptions = [];
    const selectedFromOptions = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const option of this.selectedHeartbeatFilters) {
      selectedToOptions.push(to[option]);
      selectedFromOptions.push(from[option]);
    }

    const heartbeatFrom = DateTime.fromMillis(Math.min(...selectedFromOptions)).toJSDate().toISOString();
    const heartbeatTo = DateTime.fromMillis(Math.max(...selectedToOptions)).toJSDate().toISOString();

    return { heartbeatFrom, heartbeatTo };
  }
}

export default new DeviceStateSingleton();
